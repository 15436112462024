var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CButton",
        {
          attrs: { color: "secondary", to: { name: "AdminAnnouncementCreate" } }
        },
        [_vm._v("Benachrichtigung an alle")]
      ),
      _c("br"),
      _c("DataTable", {
        attrs: {
          items: _vm.Messages,
          fields: [
            "status",
            "sender",
            "createdAt",
            "subject",
            {
              key: "actions",
              label: "",
              _style: "width:30%",
              sorter: false,
              filter: false
            }
          ],
          "table-filter-value": _vm.tableFilterValue,
          "sorter-value": _vm.sorterValue,
          loading: _vm.loading
        },
        on: {
          "update:tableFilterValue": function($event) {
            _vm.tableFilterValue = $event
          },
          "update:table-filter-value": function($event) {
            _vm.tableFilterValue = $event
          },
          "update:sorterValue": function($event) {
            _vm.sorterValue = $event
          },
          "update:sorter-value": function($event) {
            _vm.sorterValue = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("td", [_c("MessageStatus", { attrs: { message: item } })], 1)
              ]
            }
          },
          {
            key: "createdAt",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [
                    _c("FormatedTimestamp", { attrs: { date: item.createdAt } })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "CButtonToolbar",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-primary btn-md float-right m-1",
                            attrs: {
                              to: {
                                name: "AdminMessageDetail",
                                params: { messageId: item.id }
                              }
                            }
                          },
                          [_vm._v(" Details ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("CPagination", {
        attrs: { pages: _vm.pages, activePage: _vm.currentPage },
        on: {
          "update:activePage": function($event) {
            _vm.currentPage = $event
          },
          "update:active-page": function($event) {
            _vm.currentPage = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }